import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import maskImage from '@assets/images/pirulo/media-and-text-mask.svg';
import maskImageMobile from '@assets/images/pirulo/media-and-text-mask-mobile.svg';

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers([`textColumn`, `textBox`]);

export const mediaAndText = helpers.defineMultiStyleConfig({
  // The base styles for each part
  baseStyle: {
    textBox: {
      maskImage: {
        base: `url(${maskImageMobile})`,
        md: `url(${maskImage})`
      },
      maskSize: `100% 100%`,
      bgColor: `colours.colour-3`,
      bgImage: {
        base: maskImageMobile,
        md: maskImage
      },
      bgPosition: `center`,
      bgSize: `100% 100%`,
      px: { md: `10%` },
      py: { base: `2%`, md: `10%` }
    }
  }
});
