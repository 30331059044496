import React, { useRef, useEffect } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CustomEase } from 'gsap/CustomEase';
import { Box, Heading, AspectRatio } from '@chakra-ui/react';
import Image from '@components/atoms/Image';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger, CustomEase);
}

const ProductCard = ({ href, title, image, ...props }) => {
  const imageRef = useRef(null);
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: imageRef.current,
        start: `top bottom`
      }
    });
    tl.to(imageRef.current, {
      opacity: 1,
      duration: 0.42,
      immediateRender: false
    });
  }, []);
  return (
    <Box
      as={GatsbyLink}
      to={href}
      display="block"
      {...props}
      transition="transform .5s cubic-bezier(0.53, 2, 0.63, 0.53)"
      _hover={{
        transform: { md: `auto` },
        scale: { md: `1.1` }
      }}>
      {image && (
        <AspectRatio
          ref={imageRef}
          ratio={1}
          opacity={0}
          transform="auto"
          translateY="2rem">
          <Image image={image} objectFit="contain" />
        </AspectRatio>
      )}
      {title && (
        <Heading
          as="h2"
          variant="detail"
          size="lg"
          pt="6"
          textAlign="center"
          textTransform="uppercase"
          mb="0">
          <Box as="span">{title}</Box>
        </Heading>
      )}
    </Box>
  );
};

export default ProductCard;
