import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import maskImage from '@assets/images/rowntrees/media-and-text-mask.svg';
import maskImageMobile from '@assets/images/rowntrees/media-and-text-mask-mobile.svg';

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers([`textBox`]);

export const mediaAndText = helpers.defineMultiStyleConfig({
  // The base styles for each part
  baseStyle: {
    textBox: {
      bgImage: {
        base: maskImageMobile,
        md: maskImage
      },
      bgPosition: `center`,
      bgSize: `100% 100%`,
      px: { md: `10%` },
      pt: { base: `5%`, md: `10%` },
      pb: { base: `13%`, md: `10%` }
    }
  }
});
