import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers([
  `headerEl`,
  `headerLogo`,
  `headerNav`
]);

export const header = helpers.defineMultiStyleConfig({
  // The base styles for each part
  baseStyle: {
    headerEl: {
      color: `neutrals.white`,
      bg: `linear-gradient(180deg, #00783C 32.32%, rgba(0, 120, 60, 0.00) 86.46%)`
    },
    headerLogo: {
      width: { base: `6.08rem`, md: `11.4rem` }
    },
    headerNav: {
      backgroundColor: { base: `colours.colour-3`, md: `transparent` }
    }
  }
});
