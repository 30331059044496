import React from 'react';
import { Icon } from '@chakra-ui/react';

const ArrowUpIcon = (props) => (
  <Icon viewBox="0 0 14 23" fill="currentColor" {...props}>
    <g clipPath="url(#clip0_2514_15664)">
      <path
        d="M4.0428 21.4375C5.38496 15.796 5.67744 10.0422 4.96899 4.30359C4.77075 2.70733 7.45506 2.71947 7.65005 4.30359C8.38774 10.2607 8.02377 16.2512 6.62962 22.1021C6.2559 23.6741 3.66908 23.0095 4.0428 21.4375Z"
        fill="currentColor"
      />
      <path
        d="M12.359 10.3756C7.92635 9.02214 4.91707 5.49886 4.34186 1.20172L7.02292 1.20172C7.04892 3.45043 5.8855 5.44727 4.84557 7.41375C4.06888 8.88558 3.1427 11.1282 0.942602 10.5486C-0.71478 10.1116 -0.00958048 7.69598 1.6543 8.13298C1.45607 8.08139 3.0062 5.23484 3.17194 4.90102C3.77315 3.71142 4.35161 2.54306 4.33861 1.19869C4.31911 -0.458261 6.81168 -0.352046 7.01967 1.19869C7.43889 4.3305 9.88271 6.98283 13.0675 7.95696C14.6989 8.45466 14.0002 10.8733 12.3558 10.3726L12.359 10.3756Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2514_15664">
        <rect
          width="23"
          height="14"
          fill="currentColor"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 14 23)"
        />
      </clipPath>
    </defs>
  </Icon>
);

export default ArrowUpIcon;
