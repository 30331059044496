import React from 'react';
import { Box } from '@chakra-ui/react';
import { height as headerHeight } from '@components/organisms/Header';
import CenteredTextBlock from './CenteredTextBlock';

const StandardHero = ({ data }) => {
  return (
    <Box pt={headerHeight}>
      <CenteredTextBlock data={data} headingType="h1" />
    </Box>
  );
};

export default StandardHero;
