import React from 'react';
import { Wrap, WrapItem } from '@chakra-ui/react';
import { ButtonLink } from '@components/atoms/Button';
import dataLayerPush from '@helpers/dataLayerPush';

const ButtonGroup = ({ ctas, dataLayerType, size, buttonWidth, ...props }) => {
  return (
    <Wrap
      className="wrap"
      direction={{ base: `column`, md: `row` }}
      align={{ md: `center` }}
      spacingX="7"
      spacingY="6"
      {...props}>
      {ctas.map((cta, index) => {
        // Set variant based on the ctas position.
        const variant = index % 2 === 0 ? `primary` : `secondary`;
        return (
          <WrapItem key={cta.title} width={buttonWidth}>
            <ButtonLink
              variant={variant}
              size={size || `md`}
              href={cta.link}
              width="100%"
              zIndex="1"
              onClick={() => {
                dataLayerPush({
                  event: `cta_click`,
                  interaction: {
                    click_text: cta.title,
                    link_url: cta.link,
                    cta_type: dataLayerType
                  }
                });
              }}
              onTouchStart={() => {
                dataLayerPush({
                  event: `cta_click`,
                  interaction: {
                    click_text: cta.title,
                    link_url: cta.link,
                    cta_type: dataLayerType
                  }
                });
              }}>
              {cta.title}
            </ButtonLink>
          </WrapItem>
        );
      })}
    </Wrap>
  );
};

export default ButtonGroup;
