import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers([`container`]);

export const productDetail = helpers.defineMultiStyleConfig({
  // The base styles for each part
  baseStyle: {
    container: {
      backgroundColor: `colours.colour-2`
    },
    allergen: {
      fontSize: `sm`
    }
  }
});
