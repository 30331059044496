import ButtonGlow from '@assets/images/rowntrees/button-glow-bg.svg';
import ButtonDecorationLeft from '@assets/images/rowntrees/button-left.svg';
import ButtonDecorationRight from '@assets/images/rowntrees/button-right.svg';

export const components = {
  Heading: {
    baseStyle: {
      fontFamily: `heading`,
      textTransform: `uppercase`,
      letterSpacing: `0.07625rem`,
      fontWeight: `normal`,
      '&.heading-stack': {
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`
      }
    },
    sizes: {
      xs: {
        fontSize: [`lg`, `lg`, `4xl`]
      },
      sm: {
        fontSize: `4xl`
      },
      md: {
        fontSize: `4xl`
      },
      lg: {
        fontSize: [`4xl`, `4xl`, `4xl`, `4xl`, `5xl`]
      },
      xl: {
        fontSize: [`5xl`, `5xl`, `7xl`, `8xl`]
      },
      '2xl': {
        fontSize: [`5xl`, `5xl`, `7xl`]
      },
      '3xl': {
        fontSize: [`6xl`, `6xl`, `7xl`, `8xl`]
      },
      '4xl': {
        fontSize: [`5xl`, `5xl`, `7xl`]
      },
      '5xl': {
        fontSize: [`5xl`, `5xl`, `8xl`]
      }
    },
    variants: {
      // Detail heading variant will need the text wrapped in a span element to be styled.
      detail: {
        maxWidth: `100%`,
        span: {
          display: `inline-block`,
          color: `neutrals.white`,
          textAlign: `center`,
          textTransform: `uppercase`,
          backgroundColor: `colours.colour-4`,
          px: 8,
          pt: 1,
          lineHeight: 1.2,
          borderRadius: `0.625rem`,
          textShadow: `0 0.3125rem var(--chakra-colors-colours-colour-5)`,
          '&:first-of-type': {
            transform: `rotate(-0.5deg)`
          },
          '&:nth-of-type(2)': {
            transform: `rotate(1deg)`,
            marginTop: `-1.5`
          }
        }
      },
      productHero: {
        textShadow: `0 0.5rem var(--chakra-colors-colours-colour-5)`
      }
    }
  },
  Text: {
    baseStyle: {
      mb: { base: 4, md: 6 }
    },
    sizes: {
      xs: {
        fontSize: `xs`
      },
      xl: {
        fontSize: `xl`
      }
    },
    variants: {
      bold: {
        fontWeight: 900
      }
    }
  },
  List: {
    baseStyle: {
      container: {
        mb: { base: 4, md: 6 }
      }
    },
    variants: {
      popoverList: {
        item: {
          color: `neutrals.white`,
          fontSize: `sm`,
          fontWeight: `900`,
          textTransform: `uppercase`,
          borderRadius: 4,
          '&.is-active': {
            color: `neutrals.black`,
            backgroundColor: `colours.colour-2`
          },
          _hover: {
            color: `neutrals.black`,
            backgroundColor: `colours.colour-2`
          }
        }
      }
    }
  },
  Button: {
    defaultProps: {
      colorScheme: null
    },
    baseStyle: {
      position: `relative`,
      fontFamily: `body`,
      fontWeight: `900`,
      textTransform: `uppercase`,
      cursor: `pointer`
    },
    sizes: {
      ellipsed: {},
      ellipsedMedium: {
        fontSize: `2xl`
      },
      icon: {
        fontSize: `2xl`
      },
      sm: ({ variant }) => ({
        h: variant === `primary` || `secondary` ? `3.438rem` : null,
        px: variant === `primary` || `secondary` ? `2.375rem` : null,
        minW: null,
        fontSize: `md`
      }),
      md: ({ variant }) => ({
        h: variant === `primary` || `secondary` ? `4.5rem` : null,
        px: variant === `primary` || `secondary` ? `4rem` : null,
        minW: null
      })
    },
    variants: {
      primary: {
        backgroundColor: `transparent`,
        color: `colours.colour-5`,
        borderRadius: `5.5rem`,
        transition: `color .3s ease-in-out`,
        '&::before': {
          content: `""`,
          position: `absolute`,
          top: `0`,
          bottom: `0`,
          left: `0`,
          right: `0`,
          backgroundColor: `white`,
          border: `0.1875rem solid`,
          borderColor: `colours.colour-5`,
          borderRadius: `5.5rem`,
          zIndex: `-1`,
          transition: `transform .5s cubic-bezier(0.53, 2, 0.63, 0.53)`
        },
        '&::after': {
          content: `""`,
          position: `absolute`,
          top: `50%`,
          left: `50%`,
          transform: `translate(-50%, -50%)`,
          width: `100%`,
          height: `100%`,
          opacity: 0,
          backgroundImage: ButtonGlow,
          backgroundRepeat: `no-repeat`,
          backgroundPosition: `center`,
          zIndex: `-1`,
          transition: `opacity .3s ease-in-out`
        },
        '.button-hover-state::before': {
          content: `""`,
          position: `absolute`,
          top: `50%`,
          left: `0`,
          width: `6.375rem`,
          height: `8rem`,
          backgroundImage: ButtonDecorationLeft,
          backgroundRepeat: `no-repeat`,
          backgroundSize: `100%`,
          transform: `translate(0, -50%) scale(0)`,
          zIndex: `-2`,
          transition: `transform .5s cubic-bezier(0.53, 2, 0.63, 0.53)`
        },
        '.button-hover-state::after': {
          content: `""`,
          position: `absolute`,
          top: `50%`,
          right: `0`,
          width: `4.625rem`,
          height: `7rem`,
          backgroundImage: ButtonDecorationRight,
          backgroundRepeat: `no-repeat`,
          backgroundSize: `100%`,
          transform: `translate(0, -50%) scale(0)`,
          zIndex: `-2`,
          transition: `transform .5s cubic-bezier(0.53, 2, 0.63, 0.53)`
        },
        _hover: {
          color: `white`,
          '&::before': {
            backgroundColor: { md: `colours.colour-5` },
            transform: { md: `scale(1.1)` },
            boxShadow: {
              md: `0px 0.75rem 0.625rem 0px rgba(0, 0, 0, 0.25) inset;`
            }
          },
          '&::after': {
            opacity: 1
          },
          '.button-hover-state::before': {
            transform: `translate(-1.5625rem, -50%) scale(1)`
          },
          '.button-hover-state::after': {
            transform: `translate(1.875rem, -50%) scale(1)`
          }
        }
      },
      secondary: {
        backgroundColor: `transparent`,
        color: `white`,
        borderRadius: `5.5rem`,
        '&::before': {
          content: `""`,
          position: `absolute`,
          top: `0`,
          bottom: `0`,
          left: `0`,
          right: `0`,
          backgroundColor: `colours.colour-3`,
          borderRadius: `5.5rem`,
          zIndex: `-1`,
          transition: `transform .5s cubic-bezier(0.53, 2, 0.63, 0.53)`
        },
        _hover: {
          '&::before': {
            borderColor: { md: `transparent` },
            transform: { md: `scale(1.1)` }
          }
        }
      },
      tertiary: {
        color: `white`,
        textTransform: `none`,
        px: 0,
        '.button-icon-container': {
          w: 7,
          h: 7,
          bg: `transparent`,
          ml: `3`,
          border: `.125rem solid`,
          borderColor: `colours.colour-5`,
          borderRadius: `100%`,
          transition: `background .3s ease-in-out`
        },
        '.button-hover:hover &': {
          '.button-icon-container': {
            bg: `colours.colour-5`
          },
          '.button-icon-container svg': {
            color: `white`
          }
        },
        _hover: {
          '.button-icon-container': {
            bg: `colours.colour-5`
          },
          '.button-icon-container svg': {
            color: `white`
          }
        }
      },
      quaternary: {
        width: `3.5rem`,
        height: `5rem`,
        color: `white`,
        backgroundColor: `colours.colour-4`,
        border: `0.1875rem solid`,
        borderColor: `colours.colour-5`,
        _hover: {
          backgroundColor: `colours.colour-5`
        }
      },
      localeDropdown: {
        fontSize: `sm`,
        color: `neutrals.white`,
        width: `auto`,
        height: `auto`,
        p: `0`
      },
      paginationArrow: {
        color: `neutrals.white`,
        bg: `colours.colour-4`,
        transition: `background-colour .3s ease-in-out`,
        _hover: {
          bg: `colours.colour-5`
        },
        _focus: {
          bg: `colours.colour-5`
        }
      },
      paginationNumber: {
        fontWeight: `900`,
        fontSize: `xl`,
        color: `neutrals.white`,
        border: `0.1875rem solid`,
        borderColor: `neutrals.white`,
        bg: `transparent`,
        borderRadius: `100%`,
        width: { base: 12, md: 14 },
        height: { base: 12, md: 14 },
        px: `0`,
        transition: `background-colour .3s ease-in-out`,
        _hover: {
          bg: `colours.colour-3`,
          borderColor: `colours.colour-8`
        },
        _focus: {
          bg: `colours.colour-3`,
          borderColor: `colours.colour-8`
        },
        '&.is-active': {
          bg: `colours.colour-5`,
          borderColor: `transparent`
        }
      }
    }
  },
  Link: {
    baseStyle: {
      _hover: {
        textDecoration: `none`
      }
    },
    variants: {
      headerNavLink: {
        fontFamily: `heading`,
        fontWeight: `bold`,
        fontSize: { base: `4xl`, md: `2xl` },
        lineHeight: `1.2`,
        textTransform: `uppercase`,
        textDecoration: `none`
      },
      footerNavLink: {
        fontFamily: `heading`,
        fontSize: `xl`,
        lineHeight: `1.2`,
        color: `white`,
        textTransform: `uppercase`,
        textDecoration: `none`
      },
      social: {
        color: `white`,
        transition: `color .3s ease-in-out`,
        _hover: {
          color: `colours.colour-5`
        }
      }
    }
  },
  Radio: {
    baseStyle: {
      control: {
        borderWidth: `1px`,
        borderColor: `primary.dark-blue`,
        _checked: {
          background: `primary.dark-blue`,
          borderColor: `primary.dark-blue`,
          _hover: {
            background: `primary.dark-blue`,
            borderColor: `primary.dark-blue`
          }
        }
      },
      label: {
        fontFamily: `heading`,
        fontWeight: `bold`,
        color: `secondary.pink`,
        textDecoration: `underline`
      }
    },
    sizes: {
      md: {
        control: {
          w: 6,
          h: 6
        }
      }
    }
  },
  Checkbox: {
    baseStyle: {
      control: {
        borderWidth: `1px`,
        borderColor: `primary.dark-blue`,
        borderRadius: 4,
        _checked: {
          background: `primary.dark-blue`,
          borderColor: `primary.dark-blue`,
          _hover: {
            background: `primary.dark-blue`,
            borderColor: `primary.dark-blue`
          }
        }
      },
      label: {
        fontFamily: `heading`,
        fontWeight: `bold`,
        color: `secondary.pink`,
        textDecoration: `underline`
      }
    },
    sizes: {
      md: {
        control: {
          w: 6,
          h: 6
        }
      }
    }
  },
  Table: {
    baseStyle: {
      th: {
        fontWeight: `600`,
        fontFamily: null,
        textTransform: null,
        letterSpacing: null
      }
    },
    variants: {
      simple: {
        th: {
          fontSize: null,
          color: null,
          backgroundColor: `colours.colour-6`,
          borderColor: `colours.colour-7`
        },
        td: {
          borderColor: `colours.colour-7`
        }
      }
    },
    sizes: {
      md: {
        th: {
          height: { base: `5.25rem`, md: `auto` },
          p: 4
        },
        td: {
          p: 4
        }
      }
    }
  }
};
