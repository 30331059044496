import React from 'react';
import { Icon } from '@chakra-ui/react';

const ArrowUpIconRowntrees = (props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      d="M12.1132 20.1887C12.5986 20.1887 12.9921 19.7952 12.9921 19.3098L12.9921 7.43646L16.1364 10.6739C16.483 11.0308 17.0559 11.0308 17.4026 10.674C17.7353 10.3315 17.7353 9.78649 17.4025 9.44402L12.1132 4L6.8239 9.44402C6.49116 9.78649 6.49114 10.3315 6.82386 10.674C7.1705 11.0308 7.74341 11.0308 8.09001 10.6739L11.2343 7.43646L11.2343 19.3098C11.2343 19.7952 11.6278 20.1887 12.1132 20.1887Z"
      fill="currentColor"
    />
  </Icon>
);

export default ArrowUpIconRowntrees;
