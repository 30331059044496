import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext
} from 'react';
import { graphql, useStaticQuery, Link as GatsbyLink } from 'gatsby';
import dataLayerPush from '@helpers/dataLayerPush';

import {
  Box,
  Flex,
  Link,
  useDisclosure,
  HStack,
  useMultiStyleConfig
} from '@chakra-ui/react';
import LocaleOptions from '@components/molecules/LocaleOptions';
import Image from '@components/atoms/Image';
import Burger from '@components/atoms/Burger';
import SocialNav from '@components/molecules/SocialNav';
import { LocaleContext } from '@helpers/LocaleProvider';

export const height = { base: `7.375rem`, md: `11.25rem` };

const Header = ({ headerMenu, logo }) => {
  const {
    site: {
      siteMetadata: { title: siteTitle }
    },
    allStrapiSiteSetting
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allStrapiSiteSetting {
        nodes {
          locale
          socialLinks {
            title
            facebookLink
            instagramLink
            youtubeLink
            showInHeader
          }
        }
      }
    }
  `);

  // Set styles from theme.
  const styles = useMultiStyleConfig(`header`);

  // Sets the current locale.
  const { locale } = useContext(LocaleContext);

  /*
   * Find matching locale for site settings content.
   */
  const strapiSiteSetting = allStrapiSiteSetting.nodes.find(
    (node) => node.locale === locale
  );

  const { socialLinks } = strapiSiteSetting;

  const { isOpen, onToggle } = useDisclosure();
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  // const [isAtTop, setIsAtTop] = useState(true);
  const [currentNavItemIndex, setCurrentNavItemIndex] = useState(-1);

  const navItemsRef = useRef([]);

  const showNav = !isScrollingDown;

  const handleClick = useCallback((eventName, url, title, navLevel) => {
    dataLayerPush({
      event: eventName,
      interaction: {
        click_text: title,
        link_url: url,
        nav_level: navLevel || 1
      }
    });
  }, []);

  const handleNavigate = useCallback((url, title, navLevel) => {
    dataLayerPush({
      event: `nav_button_click`,
      interaction: {
        click_text: title,
        link_url: url,
        nav_level: navLevel || 1
      }
    });
  }, []);

  // Determines when the user is scrolling down to set visibility of header.
  useEffect(() => {
    let oldScroll = window.scrollY;
    let nextThreshold = 0;

    const onScroll = () => {
      const newScroll = window.scrollY;
      const atTop = newScroll <= 0;
      const scrollingDown = oldScroll < newScroll;

      setIsScrollingDown(
        !atTop &&
          (scrollingDown || (!scrollingDown && newScroll > nextThreshold))
      );

      if (atTop === true || scrollingDown === true) {
        nextThreshold = newScroll - window.innerHeight * 0.15;
      }

      oldScroll = newScroll;

      // setIsAtTop(atTop);
    };

    window.addEventListener(`scroll`, onScroll, { passive: true });

    return () =>
      window.removeEventListener(`scroll`, onScroll, { passive: true });
  }, []);

  useEffect(() => {
    /**
     * onKeyDown method for the header
     * @param {KeyboardEvent} event onKeyDown event
     */
    const onKeyDown = ({ key }) => {
      const menuItems = headerMenu.nodes[0].attributes.items.data.map(
        (item, index) => index
      );

      if (typeof key === `undefined`) {
        return;
      }

      if (key.toLowerCase() === `arrowleft`) {
        const previousIndex = currentNavItemIndex - 1;
        const previousItem =
          previousIndex < 0 ? menuItems.length - 1 : previousIndex;

        if (navItemsRef.current && navItemsRef.current.length > 0) {
          const currentElement = navItemsRef.current[previousItem];
          currentElement.focus();
        }

        setCurrentNavItemIndex(previousItem);
      }

      if (key.toLowerCase() === `arrowright`) {
        const nextIndex = currentNavItemIndex + 1;
        const nextItem = nextIndex > menuItems.length - 1 ? 0 : nextIndex;

        if (navItemsRef.current && navItemsRef.current.length > 0) {
          const currentElement = navItemsRef.current[nextItem];
          currentElement.focus();
        }

        setCurrentNavItemIndex(nextItem);
      }
    };

    window.addEventListener(`keydown`, onKeyDown);

    return () => window.removeEventListener(`keydown`, onKeyDown);
  }, [
    currentNavItemIndex,
    headerMenu.nodes,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    headerMenu.nodes[0].attributes.items.data
  ]);

  return headerMenu?.nodes[0].attributes === undefined ||
    headerMenu.nodes[0].attributes?.items?.data?.length <= 0 ? (
    // eslint-disable-next-line react/jsx-indent
    <Box color="red">Menu render error</Box>
  ) : (
    <Box position="relative" zIndex="100">
      <Flex w="100%" justifyContent="center">
        <Flex
          as="header"
          position="fixed"
          display="flex"
          w="100vw"
          h={{ base: height.base, md: height.md }}
          alignItems="center"
          justifyContent="space-between"
          px={{ base: 6, lg: 14 }}
          top={showNav || isOpen ? `0px` : `-${height.md}`}
          zIndex="2"
          transition="top 0.5s ease-in-out"
          __css={styles.headerEl}>
          <Flex
            to={`/${locale}`}
            className="logo-hover"
            as={GatsbyLink}
            position="relative"
            mr={{ base: `0`, xl: `4` }}
            zIndex="2"
            __css={styles.headerLogo}
            onClick={
              () => handleClick(`nav_logo_click`, `/`, `logo`, `logo`)
              // eslint-disable-next-line react/jsx-curly-newline
            }>
            <Image
              image={logo}
              alt={logo.alternativeText ? logo.alternativeText : siteTitle}
            />
          </Flex>
          <Flex
            as="nav"
            role="navigation"
            position={{ base: `fixed`, md: `static` }}
            top={{ base: `0`, md: `auto` }}
            right={{ base: `0`, md: `auto` }}
            left={{ base: `0`, md: `auto` }}
            bottom={{ base: `0`, md: `auto` }}
            display="flex"
            flexDirection={{ base: `column`, md: `column-reverse`, xl: `row` }}
            alignItems={{ base: `center`, md: `flex-end`, xl: `center` }}
            py={{ base: `4.125rem`, md: 0 }}
            ml="auto"
            transform="auto"
            translateY={isOpen ? `0` : { base: `-100%`, md: `0` }}
            transition=".3s ease-in-out"
            __css={styles.headerNav}>
            <HStack
              as="ul"
              px={{ base: 6, md: 0 }}
              flexDirection={{ base: `column`, md: `row` }}
              listStyleType="none"
              spacing={{ base: 6, lg: 14 }}
              pt={{ base: 10, md: 4, xl: 0 }}
              mr={{ xl: 14 }}>
              {headerMenu.nodes[0].attributes.items.data.map(
                ({ attributes: primaryItem }) => (
                  <Box as="li" key={primaryItem.title}>
                    <Link
                      href={`/${locale}${primaryItem.url}`}
                      onClick={
                        () => handleNavigate(primaryItem.url, primaryItem.title)
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                      display="block"
                      variant="headerNavLink"
                      transition="transform .5s cubic-bezier(0.53, 2, 0.63, 0.53)"
                      _hover={{
                        transform: `auto`,
                        scale: `1.1`
                      }}>
                      {primaryItem.title}
                    </Link>
                  </Box>
                )
              )}
            </HStack>
            <Box
              display="flex"
              flexDirection={{ base: `column`, md: `row` }}
              alignItems="center"
              width={{ base: `100%`, md: `auto` }}>
              {socialLinks?.showInHeader &&
                socialLinks?.showInHeader !== null && (
                  <SocialNav
                    locale={locale}
                    socialLinks={socialLinks}
                    my={{ base: 6, md: 0 }}
                    showTitle={false}
                  />
                )}
              <Box
                width={{ base: `100%`, md: `auto` }}
                minWidth={{ md: `6.75rem` }}
                mt={{ base: 6, md: 0 }}>
                <LocaleOptions />
              </Box>
            </Box>
          </Flex>
          <Flex
            display={{ base: `block`, md: `none` }}
            transform="auto"
            rotate={isOpen ? `90deg` : 0}
            transition={isOpen ? `transform .2s  ease-in-out` : null}>
            <Burger
              isOpen={isOpen}
              onClick={onToggle}
              color="#fff"
              zIndex="2"
              cursor="pointer"
            />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Header;
