import { popoverAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(popoverAnatomy.keys);

const primary = definePartsStyle({
  content: {
    bg: `neutrals.white`,
    border: `none`
  }
});

export const popoverTheme = defineMultiStyleConfig({
  variants: { primary }
});
