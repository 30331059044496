import BackgroundTexture from '@assets/images/pirulo/bg-texture.png';

export const styles = {
  global: {
    body: {
      color: `neutrals.white`,
      backgroundImage: BackgroundTexture,
      backgroundSize: `cover`,
      backgroundPosition: `center`,
      backgroundAttachment: `fixed`,
      backgroundRepeat: `no-repeat`
    }
  }
};
