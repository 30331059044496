import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

// Variant for locale options.
const localeOptions = definePartsStyle({
  container: {},
  button: {
    fontSize: `sm`,
    fontWeight: `900`,
    textTransform: `uppercase`,
    bg: `transparent`,
    _expanded: {
      bg: `colours.colour-2`
    },
    svg: {
      color: `neutrals.white`
    }
  },
  panel: {
    bg: `neutrals.white`
  }
});

export const accordionTheme = defineMultiStyleConfig({
  variants: { localeOptions }
});
