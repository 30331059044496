import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import maskImage from '@assets/images/pirulo/news-article-mask.svg';

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers([`newsCardItem`]);

export const newsCard = helpers.defineMultiStyleConfig({
  // The base styles for each part
  baseStyle: {
    newsCardItem: {
      px: 10,
      pt: { base: 8, md: 10 },
      pb: { base: 8, md: 7 },
      color: `neutrals.white`,
      maskImage: {
        base: `url(${maskImage})`,
        md: `url(${maskImage})`
      },
      maskSize: `100% 100%`,
      bgColor: `colours.colour-3`,
      bgImage: {
        base: maskImage,
        md: maskImage
      },
      bgPosition: `center`,
      bgSize: `100% 100%`
    }
  }
});
