import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { Fonts } from '@components';
import { pirulo } from '@styles/pirulo';
import { rowntrees } from '@styles/rowntrees';
import LocaleProvider from '@helpers/LocaleProvider';

export const wrapPageElement = ({ element, props }) => {
  const {
    pageContext: { locale = `en`, locales = [], themeName }
  } = props;
  return (
    <ChakraProvider theme={themeName === `rowntrees` ? rowntrees : pirulo}>
      <Fonts />
      <LocaleProvider locale={locale} locales={locales}>
        {element}
      </LocaleProvider>
    </ChakraProvider>
  );
};
