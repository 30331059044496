import React, { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import {
  useMultiStyleConfig,
  Box,
  Heading,
  Grid,
  GridItem,
  AspectRatio,
  Image as ChakraImage
} from '@chakra-ui/react';
import Share from '@components/molecules/Share';
import { height as headerHeight } from '@components/organisms/Header';
import Wrapper from '@components/atoms/Wrapper';
import Image from '@components/atoms/Image';
import LazyLoad from '@components/atoms/LazyLoad';
import { LocaleContext } from '@helpers/LocaleProvider';

const ArticleHero = ({
  data: {
    publishDate,
    title,
    image,
    seo,
    location,
    themeName,
    backgroundColour = null
  }
}) => {
  const { locale } = useContext(LocaleContext);
  const styles = useMultiStyleConfig(`articleHero`);

  const { allStrapiSiteSetting } = useStaticQuery(graphql`
    {
      allStrapiSiteSetting {
        nodes {
          locale
          articleHeroDecoration {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          articleHeroDecorationMobile {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);
  const strapiSiteSetting = allStrapiSiteSetting.nodes.find(
    (node) => node.locale === locale
  );

  const decorationImage = getImage(
    strapiSiteSetting.articleHeroDecoration?.localFile
  );
  const decorationImageMobile = getImage(
    strapiSiteSetting.articleHeroDecorationMobile?.localFile
  );
  const decorationImageAltText =
    strapiSiteSetting.articleHeroDecoration?.alternativeText;

  return (
    <Box as="section" position="relative" pt={headerHeight}>
      <LazyLoad>
        <Wrapper mb={{ base: 10, md: 8 }}>
          <Grid templateColumns={{ md: `repeat(12, 1fr)` }} columnGap={5}>
            {image && (
              <GridItem
                colStart={{ md: 2 }}
                colSpan={{ md: 10 }}
                mb={{ base: 6, md: 12 }}>
                <AspectRatio
                  ratio={{ base: 4 / 3, md: 21 / 9 }}
                  overflow="hidden"
                  display="block"
                  borderRadius={themeName === `rowntrees` ? `2rem` : 0}>
                  <Image image={image} loading="eager" />
                </AspectRatio>
              </GridItem>
            )}
            <GridItem
              colStart={{ md: 3 }}
              colSpan={{ md: 8 }}
              textAlign="center">
              {publishDate && (
                <Box as="p" mb={0} __css={styles.articleHeroDate}>
                  {publishDate}
                </Box>
              )}
              {title && (
                <Heading
                  as="h1"
                  color="white"
                  size="xl"
                  position="relative"
                  my={6}
                  textTransform="uppercase"
                  textAlign="center"
                  zIndex="1">
                  {title}
                </Heading>
              )}
              <Share
                position="relative"
                zIndex="1"
                data={{
                  metaTitle: seo.metaTitle,
                  metaDescription: seo.metaDescription,
                  link: location.href
                }}
                themeName={themeName}
              />
            </GridItem>
          </Grid>
        </Wrapper>
        {decorationImage && (
          <picture>
            {decorationImageMobile && (
              <>
                {[
                  ...decorationImageMobile.images.sources,
                  decorationImageMobile.images.fallback
                ].map((source) => (
                  <source {...source} media="(max-width: 767px)" />
                ))}
              </>
            )}
            {decorationImage.images.sources.map((source) => (
              <source {...source} />
            ))}
            <ChakraImage
              {...decorationImage.images.fallback}
              width="100%"
              htmlWidth={decorationImage.width}
              htmlHeight={decorationImage.height}
              alt={decorationImageAltText}
            />
          </picture>
        )}
        <Box h={{ base: 16, md: 20 }} bg={backgroundColour} />
      </LazyLoad>
    </Box>
  );
};

export default ArticleHero;
