import SrollTopBackground from '@assets/images/pirulo/pirulo-scroll-top-bg.svg';
import ButtonDecorationLeft from '@assets/images/pirulo/pirulo-button-left.svg';
import ButtonDecorationRight from '@assets/images/pirulo/pirulo-button-right.svg';

export const components = {
  Heading: {
    baseStyle: {
      fontWeight: `normal`,
      textTransform: `uppercase`,
      color: `neutrals.white`,
      fontFamily: `heading`,
      lineHeight: 1.2
    },
    sizes: {
      xs: {
        fontSize: [`md`, `md`, `3xl`]
      },
      sm: {
        fontSize: [`lg`, `lg`, `2xl`]
      },
      md: {
        fontSize: `3xl`
      },
      lg: {
        fontSize: [`2xl`, `2xl`, `3xl`]
      },
      xl: {
        fontSize: [`3xl`, `3xl`, `6xl`]
      },
      '2xl': {
        fontSize: [`4xl`, `4xl`, `5xl`]
      },
      '3xl': {
        fontSize: [`4xl`, `4xl`, `5xl`]
      },
      '4xl': {
        fontSize: [`5xl`, `5xl`, `6xl`]
      },
      '5xl': {
        fontSize: [`5xl`, `5xl`, `7xl`]
      }
    },
    variants: {
      detail: {
        fontFamily: `heading`
      }
    }
  },
  Text: {
    baseStyle: {
      mb: { base: 4, md: 6 }
    },
    sizes: {
      xs: {
        fontSize: `xs`
      },
      xl: {
        fontSize: `xl`
      }
    },
    variants: {
      bold: {
        fontWeight: 900
      }
    }
  },
  List: {
    baseStyle: {
      container: {
        mb: { base: 4, md: 6 }
      }
    },
    variants: {
      popoverList: {
        item: {
          color: `colours.colour-2`,
          fontSize: `sm`,
          fontWeight: `900`,
          textTransform: `uppercase`,
          borderRadius: 4,
          '&.is-active': {
            color: `neutrals.white`,
            backgroundColor: `colours.colour-2`
          },
          _hover: {
            color: `neutrals.white`,
            backgroundColor: `colours.colour-2`
          }
        }
      }
    }
  },
  Button: {
    defaultProps: {
      colorScheme: null
    },
    baseStyle: {
      position: `relative`,
      fontFamily: `body`,
      fontWeight: `900`,
      color: `neutrals.white`,
      textTransform: `uppercase`,
      cursor: `pointer`
    },
    sizes: {
      ellipsedSmall: {
        fontSize: `md`
      },
      ellipsedMedium: {
        fontSize: `2xl`
      },
      sm: ({ variant }) => ({
        h: variant === `primary` || `secondary` ? `3.375rem` : null,
        px: variant === `primary` || `secondary` ? `2.375rem` : 0,
        minW: null,
        fontSize: `md`
      }),
      md: ({ variant }) => ({
        h: variant === `primary` || `secondary` ? `4.25rem` : null,
        px: variant === `primary` || `secondary` ? `2.875rem` : null,
        minW: null
      })
    },
    variants: {
      primary: {
        backgroundColor: `transparent`,
        borderRadius: `5.5rem`,
        '::before': {
          content: `""`,
          position: `absolute`,
          top: `0`,
          bottom: `0`,
          left: `0`,
          right: `0`,
          backgroundColor: `colours.colour-4`,
          borderRadius: `5.5rem`,
          border: `0.125rem solid white`,
          willChange: `transform`,
          zIndex: `-1`,
          transition: `transform .5s cubic-bezier(0.53, 2, 0.63, 0.53)`
        },
        '.button-hover-state::before': {
          content: `""`,
          position: `absolute`,
          top: `50%`,
          left: `0`,
          width: `3.375rem`,
          height: `7.5625rem`,
          backgroundImage: ButtonDecorationLeft,
          backgroundRepeat: `no-repeat`,
          backgroundSize: `100%`,
          transform: `translate(0, -50%) scale(0)`,
          zIndex: `-2`,
          transition: `transform .5s cubic-bezier(0.53, 2, 0.63, 0.53)`
        },
        '.button-hover-state::after': {
          content: `""`,
          position: `absolute`,
          top: `50%`,
          right: `0`,
          width: `3.375rem`,
          height: `7.5625rem`,
          backgroundImage: ButtonDecorationRight,
          backgroundRepeat: `no-repeat`,
          backgroundSize: `100%`,
          transform: `translate(0, -50%) scale(0)`,
          zIndex: `-2`,
          transition: `transform .5s cubic-bezier(0.53, 2, 0.63, 0.53)`
        },
        _hover: {
          '::before': {
            transform: { md: `scale(1.1)` },
            boxShadow: { md: `0px 0.4375rem 0px 0.0625rem #005E8C;` }
          },
          '.button-hover-state::before': {
            transform: `translate(-1.875rem, -50%) scale(1)`
          },
          '.button-hover-state::after': {
            transform: `translate(2.5rem, -50%) scale(1)`
          }
        }
      },
      secondary: {
        backgroundColor: `colours.colour-2`,
        border: `0.125rem solid white`,
        borderRadius: `5.5rem`,
        willChange: `transform`,
        transition: `transform .5s cubic-bezier(0.53, 2, 0.63, 0.53)`,
        _hover: {
          transform: { md: `scale(1.1)` }
        }
      },
      tertiary: {
        position: `relative`,
        '.button-inner': {
          position: `relative`
        },
        '.button-inner::after': {
          content: `""`,
          position: `absolute`,
          left: `0`,
          bottom: `0`,
          width: `100%`,
          height: `.0625rem`,
          backgroundcolor: `neutrals.white`,
          opacity: `0`,
          transition: `opacity .3s ease-in-out`
        },
        '.button-hover:hover &': {
          '.button-inner::after': {
            opacity: 1
          }
        }
      },
      quaternary: {
        width: `4.1875rem`,
        height: `5rem`,
        borderRadius: `0`,
        backgroundImage: SrollTopBackground,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `100%`
      },
      localeDropdown: {
        fontSize: `xs`,
        fontFamily: `heading`,
        color: `neutrals.white`,
        width: `auto`,
        height: `auto`,
        p: `0`
      },
      paginationArrow: {
        color: `neutrals.white`,
        bg: `colours.colour-9`,
        transition: `background-colour .3s ease-in-out`,
        _hover: {
          bg: `colours.colour-10`
        },
        _focus: {
          bg: `colours.colour-10`
        }
      },
      paginationNumber: {
        fontWeight: `900`,
        fontSize: `xl`,
        color: `colours.colour-8`,
        border: `0.1875rem solid`,
        borderColor: `transparent`,
        bg: `neutrals.white`,
        borderRadius: `100%`,
        width: { base: 12, md: 14 },
        height: { base: 12, md: 14 },
        transition: `background-colour .3s ease-in-out`,
        _hover: {
          color: `neutrals.white`,
          bg: `colours.colour-9`,
          borderColor: `colours.colour-7`
        },
        _focus: {
          color: `neutrals.white`,
          bg: `colours.colour-9`,
          borderColor: `colours.colour-7`
        },
        '&.is-active': {
          color: `neutrals.white`,
          bg: `colours.colour-9`,
          _hover: {
            borderColor: `transparent`
          }
        }
      }
    }
  },
  Link: {
    baseStyle: {
      _hover: {
        textDecoration: `none`
      }
    },
    variants: {
      headerNavLink: {
        fontFamily: `heading`,
        fontWeight: `bold`,
        fontSize: { base: `2xl`, md: `md` },
        lineHeight: `1.2`,
        textTransform: `uppercase`,
        textDecoration: `none`
      },
      footerNavLink: {
        fontFamily: `heading`,
        fontSize: { base: `2xl`, md: `sm` },
        lineHeight: `1.2`,
        color: `neutrals.white`,
        textTransform: `uppercase`,
        textDecoration: `none`
      },
      social: {
        color: `neutrals.white`,
        transition: `color .3s ease-in-out`,
        _hover: {
          color: `colours.colour-3`
        }
      }
    }
  },
  Radio: {
    baseStyle: {
      control: {
        borderWidth: `1px`,
        borderColor: `primary.dark-blue`,
        _checked: {
          background: `primary.dark-blue`,
          borderColor: `primary.dark-blue`,
          _hover: {
            background: `primary.dark-blue`,
            borderColor: `primary.dark-blue`
          }
        }
      },
      label: {
        fontFamily: `heading`,
        fontWeight: `bold`,
        color: `secondary.pink`,
        textDecoration: `underline`
      }
    },
    sizes: {
      md: {
        control: {
          w: 6,
          h: 6
        }
      }
    }
  },
  Checkbox: {
    baseStyle: {
      control: {
        borderWidth: `1px`,
        borderColor: `primary.dark-blue`,
        borderRadius: 4,
        _checked: {
          background: `primary.dark-blue`,
          borderColor: `primary.dark-blue`,
          _hover: {
            background: `primary.dark-blue`,
            borderColor: `primary.dark-blue`
          }
        }
      },
      label: {
        fontFamily: `heading`,
        fontWeight: `bold`,
        color: `secondary.pink`,
        textDecoration: `underline`
      }
    },
    sizes: {
      md: {
        control: {
          w: 6,
          h: 6
        }
      }
    }
  },
  Table: {
    baseStyle: {
      th: {
        fontWeight: `600`,
        fontFamily: null,
        textTransform: null,
        letterSpacing: null
      }
    },
    variants: {
      simple: {
        th: {
          fontSize: null,
          color: null,
          backgroundColor: `colours.colour-4`,
          borderColor: `colours.colour-11`
        },
        td: {
          borderColor: `colours.colour-11`
        }
      }
    },
    sizes: {
      md: {
        th: {
          height: { base: `5.25rem`, md: `auto` },
          p: 4
        },
        td: {
          p: 4
        }
      }
    }
  }
};
