import React from 'react';
import { Flex } from '@chakra-ui/react';
import ShareOnSocial from 'react-share-on-social';
import Button from '@components/atoms/Button';

import favicon from '../../../static/favicon.png';

const SharePageBlock = ({
  buttonText,
  link,
  metaTitle,
  metaDescription,
  themeName,
  backgroundColour
}) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      position="relative"
      zIndex="1"
      bg={backgroundColour}>
      <ShareOnSocial
        textToShare=""
        link={link}
        linkTitle={metaTitle}
        linkMetaDesc={metaDescription}
        linkFavicon={favicon}
        noReferer>
        <Button
          variant="tertiary"
          size="icon"
          textTransform="none"
          fontFamily="body"
          fontWeight="600"
          themeName={themeName}
          icon>
          {buttonText}
        </Button>
      </ShareOnSocial>
    </Flex>
  );
};

export default SharePageBlock;
