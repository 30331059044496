import { popoverAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(popoverAnatomy.keys);

const primary = definePartsStyle({
  content: {
    bg: `colours.colour-5`,
    border: `none`
  },
  arrow: {
    bg: `colours.colour-5 !important`,
    boxShadow: `#00401C -1px -1px 0px 0px !important`
  }
});

export const popoverTheme = defineMultiStyleConfig({
  variants: { primary }
});
