import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers([`footerEl`, `footerLogo`]);

export const footer = helpers.defineMultiStyleConfig({
  // The base styles for each part
  baseStyle: {
    footerEl: {
      bg: `colours.colour-2`,
      color: `neutrals.white`,
      borderTop: `.0625rem solid`,
      borderColor: `neutrals.white`
    },
    footerLogo: {
      width: { base: `7.3125rem`, md: `5.61rem` }
    }
  }
});
