import React from 'react';
import { Global } from '@emotion/react';
import PlutoCondensedBlack from '@assets/fonts/Pluto-Cond-Black.woff2';
import StickARound from '@assets/fonts/StickARound.woff2';

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Pluto Condensed';
        font-weight: 700;
        font-style: normal;
        font-display: swap;
        src: url(${PlutoCondensedBlack}) format('woff2');
      }
      @font-face {
        font-family: 'Stick a Round';
        font-weight: 400;
        font-style: normal;
        font-display: swap;
        src: url(${StickARound}) format('woff2');
      }
    `}
  />
);

export default Fonts;
