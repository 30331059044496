import { extendTheme } from '@chakra-ui/react';

import { breakpoints } from './breakpoints';
import { colors } from './colors';
import { components } from './components';
import { fonts } from './fonts';
import { fontSizes } from './fontSizes';
import { lineHeights } from './lineHeights';
import { radii } from './radii';
import { shadows } from './shadows';
import { styles } from './styles';

// Custom styled somponents
import { accordionTheme } from './accordion';
import { articleHero } from './articleHero';
import { header } from './header';
import { footer } from './footer';
import { mediaAndText } from './mediaAndText';
import { newsCard } from './newsCard';
import { popoverTheme } from './popover';
import { sliderArrows } from './sliderArrows';
import { socialNav } from './socialNav';
import { productDetail } from './productDetail';
import { faqItems } from './faqItems';
import { productHero } from './productHero';

export const pirulo = extendTheme({
  breakpoints,
  colors,
  fonts,
  fontSizes,
  lineHeights,
  radii,
  shadows,
  styles,
  components: {
    Accordion: accordionTheme,
    articleHero,
    header,
    footer,
    mediaAndText,
    newsCard,
    Popover: popoverTheme,
    sliderArrows,
    socialNav,
    productDetail,
    faqItems,
    productHero,
    ...components
  }
});
