import React, { useCallback, useContext } from 'react';
import { navigate } from 'gatsby';
import {
  Box,
  AspectRatio,
  Image,
  ListItem,
  UnorderedList
} from '@chakra-ui/react';
import dataLayerPush from '@helpers/dataLayerPush';
import { LocaleContext } from '@helpers/LocaleProvider';
import { includedLocales } from '@helpers/environment';

const LocaleList = () => {
  const { locale, locales } = useContext(LocaleContext);

  // Sort locales alphabetically by the name value
  let localesSortedByName = locales
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));

  // Use included locales list to filter out unwanted locales
  if (includedLocales !== false) {
    localesSortedByName = localesSortedByName.filter(
      (localeItem) =>
        includedLocales.some(
          (includedLocaleItem) =>
            includedLocaleItem === localeItem.code.toLowerCase()
        ) === true
    );
  }

  const flags = require.context(`../../assets/images/flags`, true, /\.svg$/);
  const handleLocationChange = useCallback((langcode) => {
    navigate(`/${langcode}`);
    dataLayerPush({
      event: `location_link`,
      interaction: {
        click_text: `Change location`,
        location: langcode
      }
    });
  }, []);

  return (
    <UnorderedList listStyleType="none" variant="popoverList" ml={0} mb={0}>
      {localesSortedByName.map((localeItem) => {
        const isCurrentLocale = localeItem.code === locale;
        return (
          <ListItem
            key={localeItem.code}
            as="button"
            className={isCurrentLocale ? `is-active` : null}
            display="flex"
            alignItems="center"
            width="100%"
            p={2}
            mb={1}
            mr={0}
            cursor="pointer"
            onClick={() => handleLocationChange(localeItem.code)}>
            <AspectRatio
              boxSize="1rem"
              ratio="1"
              borderRadius="full"
              border="1px solid white"
              mr={3}
              overflow="hidden">
              <Image
                src={flags(`./${localeItem.code}.svg`).default}
                alt={localeItem.name}
              />
            </AspectRatio>
            <Box as="span">{localeItem.name}</Box>
          </ListItem>
        );
      })}
    </UnorderedList>
  );
};

export default LocaleList;
