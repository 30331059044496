import React from 'react';
import { Icon } from '@chakra-ui/react';

const ArrowRightIconAlt = (props) => (
  <Icon viewBox="0 0 16 16" fill="currentColor" {...props}>
    <path
      d="M2.54088 7.92453C2.54088 7.60093 2.80321 7.3386 3.1268 7.3386H11.0424L8.88405 5.2424C8.64614 5.01133 8.64611 4.62939 8.884 4.3983C9.11233 4.17649 9.47567 4.1765 9.70399 4.39833L13.3333 7.92453L9.70398 11.4507C9.47567 11.6726 9.11233 11.6726 8.884 11.4508C8.64611 11.2197 8.64614 10.8377 8.88405 10.6067L11.0424 8.51045H3.1268C2.80321 8.51045 2.54088 8.24813 2.54088 7.92453Z"
      fill="currentColor"
    />
  </Icon>
);

export default ArrowRightIconAlt;
