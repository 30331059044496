export const colors = {
  colours: {
    'colour-1': `#00AE4D`,
    'colour-2': `#05AB4E`,
    'colour-3': `#018936`,
    'colour-4': `#00783C`,
    'colour-5': `#00401C`,
    'colour-6': `#E31C12`,
    'colour-7': `#FDC200`,
    'colour-8': `#55B14C`
  },
  neutrals: {
    white: `#FFFFFF`,
    black: `#000000`
  }
};
