import React from 'react';
import { Button as ChakraButton, Center, Box } from '@chakra-ui/react';
import withLink from '@components/atoms/withLink';
import { ArrowRightIcon, ArrowRightIconAlt } from '@components/atoms/icons';

const ButtonIcon = ({ themeName }) => {
  return (
    <Center
      as="span"
      className="button-icon-container"
      ml="0.875rem"
      transition=".3s">
      {themeName === `rowntrees` ? (
        <ArrowRightIconAlt color="colours.colour-5" w={4} h={4} />
      ) : (
        <ArrowRightIcon color="white" w="1.6875rem" h="4" />
      )}
    </Center>
  );
};

const Button = ({
  children = null,
  variant = `primary`,
  size = { base: `sm`, md: `md` },
  icon,
  themeName,
  ...props
}) => {
  return (
    <ChakraButton
      variant={variant}
      size={size}
      iconSpacing=".625rem"
      {...props}>
      <Box className="button-hover-state" />
      <Box as="span" className="button-inner">
        {children}
      </Box>
      {icon && <ButtonIcon themeName={themeName} />}
    </ChakraButton>
  );
};

export default Button;

export const ButtonLink = withLink(Button);
