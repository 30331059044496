import React from 'react';
import { useMultiStyleConfig, Button, ButtonGroup } from '@chakra-ui/react';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowLeftIconAlt,
  ArrowRightIconAlt
} from '@components/atoms/icons';

const SliderArrows = ({ themeName }) => {
  const styles = useMultiStyleConfig(`sliderArrows`);
  return (
    <ButtonGroup __css={styles.sliderArrowsGroup}>
      <Button className="prev" __css={styles.sliderArrowPrev}>
        {themeName === `rowntrees` ? (
          <ArrowLeftIconAlt
            color="white"
            w={6}
            h={6}
            transition=".3s ease-in-out"
          />
        ) : (
          <ArrowLeftIcon
            color="white"
            w="1.6875rem"
            h="4"
            transition=".3s ease-in-out"
          />
        )}
      </Button>
      <Button className="next" __css={styles.sliderArrowNext}>
        {themeName === `rowntrees` ? (
          <ArrowRightIconAlt
            color="white"
            w={6}
            h={6}
            transition=".3s ease-in-out"
          />
        ) : (
          <ArrowRightIcon
            color="white"
            w="1.6875rem"
            h="4"
            transition=".3s ease-in-out"
          />
        )}
      </Button>
    </ButtonGroup>
  );
};

export default SliderArrows;
