import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers([
  `headerEl`,
  `headerLogo`,
  `headerNav`
]);

export const header = helpers.defineMultiStyleConfig({
  // The base styles for each part
  baseStyle: {
    headerEl: {
      color: `neutrals.white`,
      bgGradient: `linear(to-b, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.00) 100%)`
    },
    headerLogo: {
      width: { base: `5.61rem`, md: `8.12rem` }
    },
    headerNav: {
      backgroundColor: { base: `colours.colour-3`, md: `transparent` }
    }
  }
});
