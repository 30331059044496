import { Grid, GridItem, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import Section from '@components/atoms/Section';
import Wrapper from '@components/atoms/Wrapper';
import { ButtonLink } from '@components/atoms/Button';
import FAQItems from '@components/molecules/FAQItems';

const FAQBlock = ({ data: { tagline, title, cta, text, faqItems } }) => {
  return (
    <Section overflow="hidden">
      <Wrapper>
        <Grid
          position="relative"
          templateColumns={{
            base: `1fr`,
            lg: `repeat(auto-fit, minmax(14rem, 1fr))`
          }}
          rowGap={8}
          columnGap={[8, 28]}
          py={[`16`, `24`]}
          px={[`4`, `4`, `28`]}
          borderRadius="sm"
          zIndex="1">
          <GridItem>
            <Text fontWeight="600">{tagline}</Text>
            <Heading mt="6" size="lg">
              {title}
            </Heading>
            <Text mt="6" mb="8">
              {text}
            </Text>
            {cta && <ButtonLink href={cta.link}>{cta.title}</ButtonLink>}
          </GridItem>
          <GridItem>
            <FAQItems items={faqItems} />
          </GridItem>
        </Grid>
      </Wrapper>
    </Section>
  );
};

export default FAQBlock;
