const environment =
  process.env.CIRCLE_BRANCH_OVERRIDE ||
  process.env.CIRCLE_BRANCH ||
  process.env.PLATFORM_ENVIRONMENT_TYPE ||
  process.env.NODE_ENV;

const getCmsUrl = ({ env }) => {
  switch (env) {
    case `master`:
      return process.env.PROD_BACKEND_URL;
    case `staging`:
      return process.env.STAGING_BACKEND_URL;
    case `develop`:
      return process.env.DEV_BACKEND_URL;
    default:
      return typeof process.env.CMS_HOST !== `undefined`
        ? `${process.env.CMS_HOST}:${process.env.CMS_PORT}`
        : process.env.GATSBY_CMS_URL;
  }
};

const getSiteUrl = ({ env }) => {
  switch (env) {
    case `master`:
      return process.env.PROD_FRONTEND_URL;
    case `staging`:
      return process.env.STAGING_FRONTEND_URL;
    case `develop`:
      return process.env.DEV_FRONTEND_URL;
    default:
      return process.env.GATSBY_SITE_URL;
  }
};

const getStrapiToken = ({ env }) => {
  switch (env) {
    case `master`:
      return process.env.PROD_STRAPI_TOKEN;
    case `staging`:
      return process.env.STAGING_STRAPI_TOKEN;
    case `develop`:
      return process.env.DEV_STRAPI_TOKEN;
    default:
      return process.env.STRAPI_TOKEN;
  }
};

const getLocaleQuery = ({ env }) => {
  switch (env) {
    case `master`:
      return typeof process.env.USE_SINGLE_LOCALE_PROD !== `undefined`
        ? process.env.USE_SINGLE_LOCALE_PROD
        : `all`;
    case `staging`:
      return typeof process.env.USE_SINGLE_LOCALE_STAGING !== `undefined`
        ? process.env.USE_SINGLE_LOCALE_STAGING
        : `all`;
    case `develop`:
      return typeof process.env.USE_SINGLE_LOCALE_DEV !== `undefined`
        ? process.env.USE_SINGLE_LOCALE_DEV
        : `all`;
    default:
      return `all`;
  }
};

const includedLocales = process.env.GATSBY_INCLUDED_LOCALES
  ? process.env.GATSBY_INCLUDED_LOCALES.split(`,`)
  : false;

module.exports = {
  environment,
  cmsUrl: getCmsUrl({ env: environment }),
  siteUrl: getSiteUrl({ env: environment }),
  strapiToken: getStrapiToken({ env: environment }),
  localeQuery: getLocaleQuery({ env: environment }),
  includedLocales
};
