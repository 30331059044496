import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import ArrowsBackground from '@assets/images/pirulo/pirulo-pagination-bg.svg';
// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers([
  `sliderArrowsGroup`,
  `sliderArrowPrev`,
  `sliderArrowNext`
]);

export const sliderArrows = helpers.defineMultiStyleConfig({
  // The base styles for each part
  baseStyle: {
    sliderArrowsGroup: {
      display: `flex`,
      alignItems: `center`,
      justifyContent: `center`,
      width: `7.25rem`,
      height: `3.5625rem`,
      backgroundImage: ArrowsBackground,
      backgroundSize: `100%`,
      backgroundRepeat: `no-repeat`,
      mt: { base: `auto`, md: `0` }
    },
    sliderArrowPrev: {
      color: `neutrals.white`,
      pr: `2`,
      '&.swiper-button-disabled[disabled]': {
        cursor: `not-allowed`
      },
      '&.swiper-button-disabled[disabled] svg': {
        opacity: 0.5,
        width: 5,
        h: `.6875rem`
      }
    },
    sliderArrowNext: {
      color: `neutrals.white`,
      pl: `2`,
      '&.swiper-button-disabled[disabled]': {
        cursor: `not-allowed`
      },
      '&.swiper-button-disabled[disabled] svg': {
        opacity: 0.5,
        width: 5,
        h: `.6875rem`
      }
    }
  }
});
