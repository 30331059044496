import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers([`socialHeading`]);

export const socialNav = helpers.defineMultiStyleConfig({
  // The base styles for each part
  baseStyle: {
    socialHeading: {
      fontSize: `xl`,
      fontFamily: `heading`,
      color: `neutrals.white`,
      textTransform: `uppercase`
    }
  }
});
