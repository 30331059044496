import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

import HeroGlow from '@assets/images/rowntrees/rowntree-hero-glow.png';

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers([`homeHeroBackground`]);

export const homeHero = helpers.defineMultiStyleConfig({
  // The base styles for each part
  baseStyle: {
    homeHeroBackground: {
      '::after': {
        content: `""`,
        position: `absolute`,
        left: { base: `50%`, lg: `20%` },
        top: `50%`,
        transform: { base: `translate(-50%, -50%)`, lg: `translate(0, -50%)` },
        backgroundImage: HeroGlow,
        backgroundSize: `cover`,
        backgroundRepeat: `no-repeat`,
        backgroundPosition: { base: `center`, lg: `initial` },
        width: `100%`,
        height: `100%`,
        zIndex: `-1`
      }
    }
  }
});
