import React from 'react';
import { SimpleGrid, Box } from '@chakra-ui/react';
import Wrapper from '@components/atoms/Wrapper';
import Section from '@components/atoms/Section';
import ProductCard from '@components/molecules/ProductCard';

const ProductListing = ({ products, parentSlug, locale }) => {
  return (
    <Section>
      <Wrapper>
        <SimpleGrid
          columns={{ base: 1, md: 3 }}
          spacingX="8"
          spacingY={{ base: 8, md: 20 }}>
          {typeof products !== `undefined` &&
            products.map((product) => (
              <Box key={product.slug}>
                <ProductCard
                  href={`/${locale}/${parentSlug}/${product.slug}`}
                  title={product.title}
                  image={product.thumbnail}
                />
              </Box>
            ))}
        </SimpleGrid>
      </Wrapper>
    </Section>
  );
};

export default ProductListing;
