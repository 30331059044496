import React from 'react';
import { Box } from '@chakra-ui/react';

const Section = ({ children, ...props }) => {
  return (
    <Box as="section" pb={{ base: 12, md: 28 }} {...props}>
      {children}
    </Box>
  );
};

export default Section;
