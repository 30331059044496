import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers([
  `decoration`,
  `decorationImage`
]);

export const productHero = helpers.defineMultiStyleConfig({
  // The base styles for each part
  baseStyle: {
    decorationImage: {
      width: `100%`
    }
  }
});
