import React from 'react';
import {
  Box,
  Heading,
  SimpleGrid,
  AspectRatio,
  useMultiStyleConfig
} from '@chakra-ui/react';
import ButtonGroup from '@components/molecules/ButtonGroup';
import LexicalRichText from '@components/atoms/LexicalRichText';
import Image from '@components/atoms/Image';
import Section from '@components/atoms/Section';
import Wrapper from '@components/atoms/Wrapper';
import LazyLoad from '@components/atoms/LazyLoad';
import textureWatermelon from '@assets/images/pirulo/texture-watermelon.png';
import textureOrange from '@assets/images/pirulo/texture-orange.png';

const MediaAndText = ({
  data: {
    title,
    textContent,
    images,
    ctas,
    alignment,
    textBackground,
    themeName,
    backgroundColour = null
  }
}) => {
  const styles = useMultiStyleConfig(`mediaAndText`);
  const isImageRight = alignment === `Image Right`;

  // Use field to set text background (Pirulo only).
  let textBgImage = null;
  let textColour = `white`;

  if (themeName === `pirulo`) {
    if (textBackground === `Watermelon`) {
      textBgImage = textureWatermelon;
    } else if (textBackground === `Orange`) {
      textBgImage = textureOrange;
      textColour = `colours.colour-8`;
    }
  }

  return (
    <Section bg={backgroundColour}>
      <LazyLoad>
        <Wrapper>
          <SimpleGrid columns={{ md: 2 }} spacing="10" alignItems="center">
            {images && images.length > 0 && (
              <AspectRatio
                ratio={1}
                overflow="hidden"
                display="block"
                borderRadius="16"
                order={isImageRight ? { md: 2 } : { md: 1 }}>
                <Image image={images[0]} />
              </AspectRatio>
            )}
            <Box order={isImageRight ? { md: 1 } : { md: 2 }}>
              <Box
                position="relative"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                w={{ md: `100%` }}
                h={{ md: `100%` }}
                minHeight={{ md: `40rem` }}
                mx={{ base: `-6`, md: 0 }}
                bgImage={textBgImage}
                color={textColour}
                __css={styles.textBox}>
                <Box padding="8">
                  {title && (
                    <Heading
                      as="h2"
                      size="2xl"
                      color="inherit"
                      textTransform="uppercase"
                      mb="3">
                      {title}
                    </Heading>
                  )}
                  {textContent && (
                    <Box fontSize="lg" fontWeight="600">
                      <LexicalRichText
                        data={{ richText: { ...textContent.richText } }}
                        paddingSides="0"
                        maxWidth="none"
                      />
                    </Box>
                  )}
                  {ctas && ctas.length > 0 && (
                    <ButtonGroup
                      ctas={ctas}
                      mt={{ base: 6, md: 8 }}
                      dataLayerType="media_block"
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </SimpleGrid>
        </Wrapper>
      </LazyLoad>
    </Section>
  );
};

export default MediaAndText;
