import React, { useContext } from 'react';
import { graphql, useStaticQuery, Link as GatsbyLink } from 'gatsby';
import { useMultiStyleConfig, Box, Flex, Text } from '@chakra-ui/react';
import { LocaleContext } from '@helpers/LocaleProvider';
import FooterNav from '@components/molecules/FooterNav';
import SocialNav from '@components/molecules/SocialNav';
import Wrapper from '@components/atoms/Wrapper';
import Image from '@components/atoms/Image';
import LazyLoad from '../atoms/LazyLoad';

const Footer = ({ footerMenu, logo }) => {
  const {
    site: {
      siteMetadata: { title: siteTitle }
    },
    allStrapiFooter,
    allStrapiSiteSetting
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
      allStrapiFooter {
        nodes {
          locale
          copyright
        }
      }
      allStrapiSiteSetting {
        nodes {
          locale
          socialLinks {
            title
            facebookLink
            instagramLink
            youtubeLink
            showInFooter
          }
        }
      }
    }
  `);

  const styles = useMultiStyleConfig(`footer`);
  /*
   * Sets the current locale .
   */
  const { locale } = useContext(LocaleContext);

  if (!allStrapiFooter) {
    return null;
  }

  /*
   * Find matching locale for footer content.
   */
  const strapiFooter = allStrapiFooter.nodes.find(
    (node) => node.locale === locale
  );

  /*
   * Find matching locale for site settings content.
   */
  const strapiSiteSetting = allStrapiSiteSetting.nodes.find(
    (node) => node.locale === locale
  );

  const { copyright } = strapiFooter;
  const { socialLinks } = strapiSiteSetting;
  return (
    <Box as="footer" pt={10} pb={{ base: 10, lg: 20 }} __css={styles.footerEl}>
      <LazyLoad>
        <Wrapper maxWidth="none">
          <Flex
            direction={{
              base: `column`,
              lg: `row`
            }}
            alignItems={{ lg: `flex-start` }}
            justifyContent={{ md: `space-between` }}>
            <Box mb={{ base: 10, lg: 0 }} mr={{ lg: 10 }}>
              <Flex
                as={GatsbyLink}
                to={`/${locale}`}
                className="logo-hover"
                position="relative"
                display="block"
                mx={{ base: `auto`, lg: 0 }}
                mb={{ base: 6, md: 4 }}
                zIndex="2"
                __css={styles.footerLogo}>
                <Image
                  image={logo}
                  alt={logo.alternativeText ? logo.alternativeText : siteTitle}
                />
              </Flex>
              {copyright && (
                <Text
                  mb="0"
                  textAlign={{ base: `center`, lg: `left` }}
                  size="xs">
                  {copyright}
                </Text>
              )}
            </Box>
            <Flex
              width={{ lg: `100%`, xl: `auto` }}
              direction={{ base: `column`, lg: `row` }}
              alignItems={{ lg: `center` }}
              justifyContent={{ lg: `flex-end` }}
              gap={{ lg: `6.6rem` }}>
              <FooterNav locale={locale} footerMenu={footerMenu} />
              {socialLinks?.showInFooter &&
                socialLinks?.showInFooter !== null && (
                  <SocialNav
                    locale={locale}
                    socialLinks={socialLinks}
                    mt={{ base: 6, lg: 0 }}
                    showTitle
                  />
                )}
            </Flex>
          </Flex>
        </Wrapper>
      </LazyLoad>
    </Box>
  );
};

export default Footer;
