import React, { useEffect, useRef } from 'react';
import { Box, Heading, Flex, VStack, Text } from '@chakra-ui/react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CustomEase } from 'gsap/CustomEase';
import Wrapper from '@components/atoms/Wrapper';
import Image from '@components/atoms/Image';
import ButtonGroup from '@components/molecules/ButtonGroup';
import Glow from '@assets/images/rowntrees/bg-glow.png';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger, CustomEase);
}

const CenteredTextBlock = ({
  data: {
    titleLineOne,
    titleLineTwo,
    smallSizeTitle,
    text,
    ctas,
    backgroundImage,
    backgroundGlow,
    removeBottomSpacing,
    themeName,
    backgroundColour = null
  },
  headingType
}) => {
  const containerRef = useRef(null);
  const titleRef = useRef(null);
  const textRef = useRef(null);
  const btnGroupRef = useRef(null);
  const bgImageRef = useRef(null);
  useEffect(() => {
    const titleTl = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: `top center`
      }
    });
    if (titleRef.current != null) {
      titleTl.to(titleRef.current, {
        opacity: 1,
        duration: 0.4
      });
      titleTl.to(
        titleRef.current,
        {
          scale: 1,
          y: 0,
          duration: 1,
          ease: CustomEase.create(
            `custom`,
            `M0,0 C0,0 0.134,1.395 0.389,1.371 0.538,1.356 0.515,0.941 0.665,0.903 0.791,0.87 0.855,0.976 0.933,1 1.01,1.023 1,1 1,1 `
          )
        },
        `<`
      );
    }
    if (textRef.current != null) {
      gsap.to(textRef.current, {
        opacity: 1,
        duration: 0.4,
        ease: `power1.out`,
        delay: 1.5,
        scrollTrigger: {
          trigger: containerRef.current,
          start: `top center`
        }
      });
    }
    if (btnGroupRef.current != null) {
      gsap.to(btnGroupRef.current, {
        opacity: 1,
        duration: 0.4,
        delay: 2,
        ease: `power1.out`,
        scrollTrigger: {
          trigger: containerRef.current,
          start: `top center`
        }
      });
    }
    if (bgImageRef.current != null) {
      gsap.to(bgImageRef.current, {
        scale: 1,
        duration: 1,
        delay: 1,
        ease: `power1.out`,
        scrollTrigger: {
          trigger: containerRef.current,
          start: `top center`
        }
      });
    }
  });
  return (
    <Flex
      as="section"
      position="relative"
      bg={backgroundColour}
      pt={{ base: 12, md: 36 }}
      pb={removeBottomSpacing ? 0 : { base: 12, md: 36 }}>
      {backgroundImage && (
        <Box
          ref={bgImageRef}
          position="absolute"
          w="100%"
          h="100%"
          left="0"
          right="0"
          top="0"
          bottom="0"
          scale={0}
          transform="auto"
          zIndex="0"
          pointerEvents="none"
          willChange="transform">
          <Image image={backgroundImage} />
        </Box>
      )}
      {backgroundGlow && themeName === `rowntrees` && (
        <Box
          position="absolute"
          top="50%"
          left="50%"
          width="100%"
          height="100%"
          transform="translate(-50%, -50%)"
          backgroundImage={Glow}
          backgroundPosition="center"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          zIndex="-1"
        />
      )}
      <Wrapper zIndex="1">
        <VStack
          alignItems="normal"
          spacing={{ base: 8, md: 10 }}
          ref={containerRef}>
          {(titleLineOne || titleLineTwo) && (
            <Heading
              as={headingType || `h2`}
              variant="detail"
              size={smallSizeTitle ? `4xl` : `5xl`}
              ref={titleRef}
              className="heading-stack"
              position="relative"
              mb="0"
              textTransform="uppercase"
              textAlign="center"
              maxWidth={{ md: `64rem` }}
              mx="auto"
              opacity={0}
              scale={0}
              transform="auto"
              translateY="2rem"
              willChange="transform, opacity">
              <Box as="span" display="block">
                {titleLineOne}
              </Box>
              <Box as="span" display="block">
                {titleLineTwo}
              </Box>
            </Heading>
          )}
          {text && (
            <Text
              ref={textRef}
              textAlign="center"
              opacity="0"
              fontSize={{ base: `xl`, md: `2xl` }}
              fontWeight="600"
              maxWidth={{ md: `40.75rem` }}
              mx="auto"
              willChange="opacity">
              {text}
            </Text>
          )}
          {ctas && ctas.length > 0 && (
            <Box ref={btnGroupRef} opacity={0} willChange="opacity">
              <ButtonGroup
                ctas={ctas}
                justify="center"
                dataLayerType="home_hero"
                size="md"
              />
            </Box>
          )}
        </VStack>
      </Wrapper>
    </Flex>
  );
};

export default CenteredTextBlock;
