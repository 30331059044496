import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers([
  `decoration`,
  `decorationImage`
]);

export const productHero = helpers.defineMultiStyleConfig({
  // The base styles for each part
  baseStyle: {
    decoration: {
      position: { md: `relative` },
      bgGradient: `linear(to-b, colours.colour-2, colours.colour-3)`,
      height: { md: `21.25rem` },
      overflow: { md: `hidden` }
    },
    decorationImage: {
      position: { md: `absolute` },
      left: { md: `50%` },
      width: { base: `100%`, md: `auto` },
      height: { md: `100%` },
      maxWidth: { md: `none` },
      transform: { md: `translateX(-50%)` }
    }
  }
});
