import React from 'react';
import { Icon } from '@chakra-ui/react';

const ArrowRightIcon = (props) => (
  <Icon viewBox="0 0 27 16" fill="currentColor" {...props}>
    <g clipPath="url(#clip0_2701_59)">
      <path
        d="M1.83423 11.3797C8.45687 9.84576 15.2113 9.5115 21.948 10.3212C23.8218 10.5477 23.8076 7.47993 21.948 7.25709C14.9548 6.41401 7.9225 6.82998 1.05405 8.42329C-0.791314 8.85041 -0.0111324 11.8068 1.83423 11.3797Z"
        fill="currentColor"
      />
      <path
        d="M14.82 1.87536C16.4088 6.94129 20.5449 10.3805 25.5893 11.0379V7.97379C22.9495 7.94407 20.6054 9.27369 18.2969 10.4622C16.5691 11.3498 13.9365 12.4083 14.6169 14.9227C15.1299 16.8169 17.9656 16.0109 17.4526 14.1093C17.5132 14.3359 20.8548 12.5643 21.2467 12.3749C22.6432 11.6878 24.0147 11.0267 25.5929 11.0416C27.538 11.0638 27.4133 8.2152 25.5929 7.9775C21.9164 7.49839 18.8028 4.70545 17.6593 1.06571C17.075 -0.798731 14.2357 -0.00021635 14.8235 1.87908L14.82 1.87536Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2701_59">
        <rect width="27" height="16" fill="currentColor" />
      </clipPath>
    </defs>
  </Icon>
);

export default ArrowRightIcon;
