import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import {
  useMultiStyleConfig,
  Box,
  Heading,
  AspectRatio
} from '@chakra-ui/react';
import 'swiper/css';
import Image from '@components/atoms/Image';
import { ButtonLink } from '@components/atoms/Button';
import dataLayerPush from '@helpers/dataLayerPush';

const NewsCard = ({ link, image, title, themeName, ctaText }) => {
  // Set theme styles for news card.
  const styles = useMultiStyleConfig(`newsCard`);

  return (
    <Box className="button-hover" __css={styles.newsCardItem}>
      <Box as={GatsbyLink} to={link}>
        <AspectRatio
          ratio={4 / 3}
          overflow="hidden"
          display="block"
          borderRadius={
            themeName === `rowntrees` ? { base: `1rem`, md: `2rem` } : null
          }>
          <Image image={image} />
        </AspectRatio>
        <Heading
          as="h2"
          size="xs"
          pt={{ base: 6, md: 8 }}
          mb={{ base: 6 }}
          textTransform="uppercase">
          {title}
        </Heading>
      </Box>
      <ButtonLink
        href={link}
        variant="tertiary"
        size="icon"
        icon
        themeName={themeName}
        onClick={() => {
          dataLayerPush({
            event: `cta_click`,
            interaction: {
              click_text: title,
              link_url: link,
              cta_type: `news_card`
            }
          });
        }}>
        {ctaText}
      </ButtonLink>
    </Box>
  );
};

export default NewsCard;
