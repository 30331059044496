export const colors = {
  colours: {
    'colour-1': `#008ACF`,
    'colour-2': `#0077B2`,
    'colour-3': `#005E8C`,
    'colour-4': `#6F9C13`,
    'colour-5': `#00B1EA`,
    'colour-6': `#EFF0F2`,
    'colour-7': `#D26EA3`,
    'colour-8': `#001E64`,
    'colour-9': `#C83758`,
    'colour-10': `#941935`,
    'colour-11': `#E3A51A`
  },
  neutrals: {
    white: `#FFFFFF`,
    black: `#000000`
  }
};
