import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers([`footerEl`, `footerLogo`]);

export const footer = helpers.defineMultiStyleConfig({
  // The base styles for each part
  baseStyle: {
    footerEl: {
      bg: `colours.colour-3`,
      color: `neutrals.white`,
      borderTop: `0.3125rem solid`,
      borderColor: `colours.colour-4`
    },
    footerLogo: {
      width: { base: `6.08rem`, md: `7.6rem` }
    }
  }
});
