import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers([`newsCardItem`]);

export const newsCard = helpers.defineMultiStyleConfig({
  // The base styles for each part
  baseStyle: {
    newsCardItem: {
      background: `colours.colour-4`,
      px: { base: 6, md: 10 },
      pt: { base: 6, md: 10 },
      pb: { base: 3.5, md: 7 },
      borderRadius: { base: `1rem`, md: `2rem` },
      color: `neutrals.white`
    }
  }
});
