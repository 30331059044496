import React from 'react';
import Button from '@components/atoms/Button';

const PaginationButton = ({
  value = ``,
  isActive = false,
  onClick = () => {}
}) => (
  <Button
    variant="paginationNumber"
    size="ellipsed"
    className={isActive ? `is-active` : null}
    onClick={onClick}>
    {value}
  </Button>
);

export default PaginationButton;
