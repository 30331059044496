import React from 'react';
import { Icon } from '@chakra-ui/react';

const ArrowLeftIcon = (props) => (
  <Icon viewBox="0 0 27 16" fill="currentColor" {...props}>
    <path
      d="M25.1661 11.3761C18.5448 9.84196 11.7917 9.50763 5.05637 10.3174C3.18288 10.544 3.19713 7.4757 5.05637 7.25281C12.0481 6.40958 19.0791 6.82562 25.9462 8.41923C27.7912 8.84642 27.0111 11.8033 25.1661 11.3761Z"
      fill="currentColor"
    />
    <path
      d="M12.183 1.87042C10.5944 6.93727 6.45921 10.3771 1.41574 11.0346V7.96996C4.05502 7.94024 6.39866 9.2701 8.70669 10.4588C10.4341 11.3466 13.0663 12.4053 12.386 14.9202C11.8731 16.8146 9.03793 16.0086 9.55083 14.1066C9.49028 14.3332 6.14934 12.5613 5.75754 12.3719C4.36133 11.6847 2.99005 11.0234 1.41218 11.0383C-0.532543 11.0606 -0.407881 8.21141 1.41218 7.97367C5.08793 7.49447 8.20092 4.70102 9.34425 1.06062C9.92838 -0.804156 12.7671 -0.00549694 12.1794 1.87414L12.183 1.87042Z"
      fill="currentColor"
    />
  </Icon>
);

export default ArrowLeftIcon;
