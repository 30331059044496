import React, { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Hide, HStack, Flex } from '@chakra-ui/react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { ButtonLink } from '@components/atoms/Button';
import SliderArrows from '@components/molecules/SliderArrows';
import SliderPagination from '@components/molecules/SliderPagination';
import Section from '@components/atoms/Section';
import Wrapper from '@components/atoms/Wrapper';
import ProductCard from '@components/molecules/ProductCard';
import { LocaleContext } from '@helpers/LocaleProvider';
import dataLayerPush from '@helpers/dataLayerPush';
import LazyLoad from '../atoms/LazyLoad';

const ProductCarousel = ({
  data: { products, cta, themeName, backgroundColour = null }
}) => {
  // Sets the current locale .
  const { locale } = useContext(LocaleContext);

  // Query allStrapiProductListing to get slug of product listing page.
  const { allStrapiProductListing } = useStaticQuery(graphql`
    {
      allStrapiProductListing {
        nodes {
          locale
          slug
        }
      }
    }
  `);

  // Find matching locale.
  const strapiProductListing = allStrapiProductListing.nodes.find(
    (node) => node.locale === locale
  );

  return (
    <Section
      overflow="hidden"
      bg={backgroundColour}
      sx={{
        '.swiper': {
          py: 1, // to prevent overflow hidden cropping focus state on buttons.
          overflow: `visible`
        },
        '.swiper-wrapper': {
          width: `fit-content` // to ensure correct width is set on slider wrapper and dragging works.
        }
      }}>
      <LazyLoad>
        <Wrapper>
          <Swiper
            modules={[Navigation, Pagination]}
            navigation={{
              prevEl: `.prev`,
              nextEl: `.next`
            }}
            pagination={{ el: `.pagination`, clickable: true }}
            spaceBetween={20}
            slidesPerView={1.05}
            breakpoints={{
              768: {
                slidesPerView: 2
              },
              1024: {
                slidesPerView: 3
              }
            }}>
            {products &&
              products.map((product) => (
                <SwiperSlide key={product.title} className="product-hover">
                  <ProductCard
                    href={`/${locale}/${strapiProductListing.slug}/${product.slug}`}
                    title={product.title}
                    image={product.thumbnail}
                  />
                </SwiperSlide>
              ))}
            <Flex
              slot="container-end"
              align="center"
              justify={{ md: `space-between` }}
              direction={{ base: `column`, md: `row-reverse` }}
              mt={{ base: 8, md: 16 }}>
              <HStack align={{ md: `center` }} spacing={{ md: 8 }}>
                <Hide below="md">
                  <SliderArrows themeName={themeName} />
                </Hide>
                <SliderPagination />
              </HStack>
              {cta && (
                <Hide below="md">
                  <ButtonLink
                    variant="primary"
                    href={cta.link}
                    size="md"
                    mt={{ base: 8, md: 0 }}
                    onClick={() => {
                      dataLayerPush({
                        event: `cta_click`,
                        interaction: {
                          click_text: cta.title,
                          link_url: cta.link,
                          cta_type: `product_carousel`
                        }
                      });
                    }}>
                    {cta.title}
                  </ButtonLink>
                </Hide>
              )}
            </Flex>
          </Swiper>
        </Wrapper>
      </LazyLoad>
    </Section>
  );
};

export default ProductCarousel;
