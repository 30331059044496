import React, { useCallback, useContext } from 'react';
import { navigate, graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import {
  Box,
  Heading,
  Text,
  Grid,
  GridItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  ListItem,
  UnorderedList,
  Image as ChakraImage,
  useDisclosure,
  useMultiStyleConfig
} from '@chakra-ui/react';
import { height as headerHeight } from '@components/organisms/Header';
import Wrapper from '@components/atoms/Wrapper';
import Image from '@components/atoms/Image';
import { LocaleContext } from '@helpers/LocaleProvider';
import { ChevronDownIcon } from '@chakra-ui/icons';
import dataLayerPush from '@helpers/dataLayerPush';
import LazyLoad from '../atoms/LazyLoad';

const ProductHero = ({
  title,
  subtitle,
  description,
  image,
  productVariants,
  productSize,
  decoration,
  decorationMobile
}) => {
  const styles = useMultiStyleConfig(`productHero`);

  // Sets the current locale .
  const { locale } = useContext(LocaleContext);

  // Query allStrapiProductListing to get slug of product listing page.
  const { allStrapiProductListing, allStrapiSiteSetting } =
    useStaticQuery(graphql`
      {
        allStrapiProductListing {
          nodes {
            locale
            slug
          }
        }
        allStrapiSiteSetting {
          nodes {
            locale
            productHeroDecoration {
              alternativeText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            productHeroDecorationMobile {
              alternativeText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    `);

  // Find matching locale.
  const strapiProductListing = allStrapiProductListing.nodes.find(
    (node) => node.locale === locale
  );
  const strapiSiteSetting = allStrapiSiteSetting.nodes.find(
    (node) => node.locale === locale
  );
  const basePath = `${locale}/${strapiProductListing.slug}`;

  // Set state for popover.
  const { isOpen, onToggle, onClose } = useDisclosure();

  // Handle product variant change.
  const handleLocationChange = useCallback(
    (productSlug) => {
      navigate(`/${basePath}/${productSlug}`);
      dataLayerPush({
        event: `product_variant_link`,
        interaction: {
          click_text: `Change product variant`,
          location: productSlug
        }
      });
    },
    [basePath]
  );

  // Get decoration image.
  const decorationImage = getImage(
    decoration?.localFile || strapiSiteSetting.productHeroDecoration?.localFile
  );
  const decorationImageMobile = getImage(
    decorationMobile?.localFile ||
      strapiSiteSetting.productHeroDecorationMobile?.localFile
  );
  const decorationImageAltText =
    decoration?.alternativeText ||
    strapiSiteSetting.productHeroDecoration?.alternativeText;

  return (
    <Box as="section" position="relative">
      <Wrapper>
        <Grid
          className="grid"
          position="relative"
          templateColumns={{ md: `repeat(12, 1fr)` }}
          columnGap={5}
          alignItems="center"
          height={{ md: `100vh` }}
          minHeight={{ lg: `44rem` }}
          maxHeight={{ lg: `50rem` }}
          pt={{ base: `6.625rem`, md: headerHeight }}
          pb={10}
          zIndex={1}>
          <GridItem
            order={{ md: 2 }}
            colStart={{ xl: 7 }}
            colSpan={{ md: 6 }}
            mb={{ base: 6, md: 0 }}
            sx={{
              '> .gatsby-image-wrapper': {
                flexGrow: 1
              }
            }}>
            <LazyLoad>
              {image && <Image image={image} loading="eager" />}
            </LazyLoad>
          </GridItem>
          <GridItem
            position="relative"
            order={{ md: 1 }}
            colStart={{ xl: 2 }}
            colSpan={{ md: 6, xl: 4 }}
            textAlign={{ base: `center`, md: `left` }}>
            {title && (
              <LazyLoad>
                <Heading
                  as="h1"
                  size="3xl"
                  lineHeight="1.1"
                  mb="8"
                  textTransform="uppercase"
                  variant="productHero">
                  {title}
                </Heading>
              </LazyLoad>
            )}
            {subtitle && (
              <LazyLoad>
                <Text fontSize="2xl" mb="4" fontWeight="900">
                  {subtitle}
                </Text>
              </LazyLoad>
            )}
            {productVariants?.length > 0 && (
              <LazyLoad position="relative" zIndex="1">
                <Popover isOpen={isOpen} onClose={onClose} variant="primary">
                  <Box
                    as="button"
                    display="flex"
                    alignItems="center"
                    fontSize="2xl"
                    mb="4"
                    fontWeight="900"
                    onClick={onToggle}>
                    {productSize}
                    <PopoverTrigger>
                      <ChevronDownIcon
                        boxSize={7}
                        color="neutrals.white"
                        ml={1}
                      />
                    </PopoverTrigger>
                  </Box>
                  <PopoverContent
                    width="100%"
                    minWidth="12.3125rem"
                    borderRadius={8}
                    pt={6}
                    px={6}
                    mt={2}>
                    <PopoverArrow />
                    <PopoverBody pt={0} px={0} pb={6} overflow="auto">
                      <UnorderedList
                        listStyleType="none"
                        ml={0}
                        mb={0}
                        variant="popoverList">
                        {productVariants.map((variant) => {
                          return (
                            variant.product !== null && (
                              <ListItem
                                key={variant.id}
                                as="button"
                                className={
                                  variant.currentVariant ? `is-active` : null
                                }
                                display="flex"
                                alignItems="center"
                                width="100%"
                                p={2}
                                mb={1}
                                mr={0}
                                cursor="pointer"
                                onClick={() => {
                                  return handleLocationChange(
                                    variant.product.slug
                                  );
                                }}>
                                {variant.productVarientSize}
                              </ListItem>
                            )
                          );
                        })}
                      </UnorderedList>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </LazyLoad>
            )}
            {description && (
              <LazyLoad>
                <Text fontSize="xl" fontWeight="600">
                  {description}
                </Text>
              </LazyLoad>
            )}
          </GridItem>
        </Grid>
      </Wrapper>
      {decorationImage && (
        <Box __css={styles.decoration}>
          <picture>
            {decorationImageMobile && (
              <>
                {[
                  ...decorationImageMobile.images.sources,
                  decorationImageMobile.images.fallback
                ].map((source) => (
                  <source {...source} media="(max-width: 767px)" />
                ))}
              </>
            )}
            {decorationImage.images.sources.map((source) => (
              <source {...source} />
            ))}
            <ChakraImage
              {...decorationImage.images.fallback}
              htmlWidth={decorationImage.width}
              htmlHeight={decorationImage.height}
              alt={decorationImageAltText}
              __css={styles.decorationImage}
            />
          </picture>
        </Box>
      )}
    </Box>
  );
};

export default ProductHero;
