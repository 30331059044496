import React, { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Box, HStack } from '@chakra-ui/react';
import Section from '@components/atoms/Section';
import Wrapper from '@components/atoms/Wrapper';
import { LocaleContext } from '@helpers/LocaleProvider';
import NewsCard from '@components/molecules/NewsCard';
import LazyLoad from '../atoms/LazyLoad';

const OtherNewsRow = ({
  data: { articles, ctaText, themeName, backgroundColour = null }
}) => {
  // Sets the current locale .
  const { locale } = useContext(LocaleContext);
  // Query allStrapiArticleListing to get slug of product listing page.
  const { allStrapiArticleListing } = useStaticQuery(graphql`
    {
      allStrapiArticleListing {
        nodes {
          locale
          slug
        }
      }
    }
  `);

  // Find matching locale.
  const strapiArticleListing = allStrapiArticleListing.nodes.find(
    (node) => node.locale === locale
  );
  const basePath = `${locale}/${strapiArticleListing.slug}`;

  return (
    <Section bg={backgroundColour} mb={0}>
      <LazyLoad>
        <Wrapper>
          <HStack flexWrap="wrap" justifyContent={{ md: `space-between` }}>
            {articles?.map((article) => (
              <Box
                key={article.id}
                width={{ base: `100%`, md: `calc(50% - 2rem)` }}
                mb={{ base: 6, md: 28 }}>
                <LazyLoad>
                  <NewsCard
                    link={`/${basePath}/${article.slug}`}
                    image={article.thumbnail}
                    title={article.title}
                    themeName={themeName}
                    ctaText={ctaText}
                  />
                </LazyLoad>
              </Box>
            ))}
          </HStack>
        </Wrapper>
      </LazyLoad>
    </Section>
  );
};

export default OtherNewsRow;
