import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

import DateBackground from '@assets/images/pirulo/bg-date.svg';

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers([`articleHeroDate`]);

export const articleHero = helpers.defineMultiStyleConfig({
  // The base styles for each part
  baseStyle: {
    articleHeroDate: {
      position: `relative`,
      height: `3.375rem`,
      color: `neutrals.white`,
      fontSize: `xl`,
      fontWeight: `900`,
      lineHeight: `3.375rem`,
      '::after': {
        content: `""`,
        position: `absolute`,
        left: `50%`,
        top: `50%`,
        transform: `translate(-50%, -50%)`,
        backgroundImage: DateBackground,
        backgroundSize: `contain`,
        backgroundRepeat: `no-repeat`,
        backgroundPosition: `center`,
        width: `100%`,
        maxWidth: `13rem`,
        height: `100%`,
        zIndex: `-1`
      }
    }
  }
});
