import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers([
  `sliderArrowsGroup`,
  `sliderArrowPrev`,
  `sliderArrowNext`
]);

export const sliderArrows = helpers.defineMultiStyleConfig({
  // The base styles for each part
  baseStyle: {
    sliderArrowsGroup: {
      display: `flex`,
      alignItems: `center`,
      justifyContent: `center`,
      w: `5.625rem`,
      h: `3.5rem`,
      borderRadius: `3.5rem`,
      border: `.125rem solid white`
    },
    sliderArrowPrev: {
      color: `neutrals.white`,
      pr: `2.5`,
      '&.swiper-button-disabled[disabled]': {
        cursor: `not-allowed`,
        opacity: 0.5
      }
    },
    sliderArrowNext: {
      color: `neutrals.white`,
      '&.swiper-button-disabled[disabled]': {
        cursor: `not-allowed`,
        opacity: 0.5
      }
    }
  }
});
