import React from 'react';
import { Box, HStack, useMultiStyleConfig } from '@chakra-ui/react';
import Link from '@components/atoms/Link';
import {
  InstagramIcon,
  FacebookIcon,
  YoutubeIcon
} from '@components/atoms/icons';

const SocialNav = ({ socialLinks, showTitle, ...props }) => {
  const styles = useMultiStyleConfig(`socialNav`);
  return (
    <Box {...props}>
      <HStack gap={4} justifyContent={{ base: `center`, lg: `flex-start` }}>
        {typeof socialLinks?.title !== `undefined` &&
          showTitle &&
          socialLinks?.title !== null && (
            <Box as="p" __css={styles.socialHeading} mb="0">
              {socialLinks.title}
            </Box>
          )}
        {typeof socialLinks?.youtubeLink !== `undefined` &&
          socialLinks?.youtubeLink !== null && (
            <Link variant="social" href={socialLinks.youtubeLink}>
              <YoutubeIcon w={8} h={8} />
            </Link>
          )}
        {typeof socialLinks?.instagramLink !== `undefined` &&
          socialLinks?.instagramLink !== null && (
            <Link variant="social" href={socialLinks.instagramLink}>
              <InstagramIcon w={8} h={8} />
            </Link>
          )}
        {typeof socialLinks?.facebookLink !== `undefined` &&
          socialLinks?.facebookLink !== null && (
            <Link variant="social" href={socialLinks.facebookLink}>
              <FacebookIcon w={8} h={8} />
            </Link>
          )}
      </HStack>
    </Box>
  );
};

export default SocialNav;
