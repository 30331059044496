import React, { useCallback, useEffect, useState } from 'react';
import { Box, IconButton } from '@chakra-ui/react';
import { ArrowUpIcon, ArrowUpIconRowntrees } from '@components/atoms/icons';

const ScrollToTop = ({ text, themeName }) => {
  const [displayButton, setDisplayButton] = useState(false);
  const [initialHeight, setInitialHeight] = useState(0);

  const handleScrollToTop = useCallback(() => {
    if (typeof window !== `undefined`) {
      window.scrollTo({
        top: 0,
        behavior: `smooth`
      });
    }
  }, []);

  useEffect(() => {
    if (typeof document === `undefined`) {
      return () => {};
    }

    const onWindowScroll = () => {
      setDisplayButton(initialHeight > 0 && window.scrollY > initialHeight);
    };

    document.addEventListener(`scroll`, onWindowScroll);

    return () => document.removeEventListener(`scroll`, onWindowScroll);
  }, [initialHeight]);

  useEffect(() => {
    if (typeof window === `undefined`) {
      return () => {};
    }

    const onWindowResized = () => {
      const { innerHeight } = window;
      setDisplayButton(false);
      setInitialHeight(innerHeight);
    };

    window.addEventListener(`resize`, onWindowResized);

    return () => window.removeEventListener(`resize`, onWindowResized);
  }, []);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const { innerHeight } = window;
      setDisplayButton(window.scrollY > 0);
      setInitialHeight(innerHeight);
    }
  }, []);
  return (
    <Box
      pos="fixed"
      bottom={{ base: 6, md: 14 }}
      right={{ base: 6, md: 14 }}
      zIndex="99">
      <IconButton
        disabled={displayButton === false}
        opacity={displayButton === false ? `0` : `1`}
        transition="opacity .5s, background-color .3s"
        variant="quaternary"
        aria-label={text}
        icon={
          themeName === `rowntrees` ? (
            <ArrowUpIconRowntrees w={6} h={6} />
          ) : (
            <ArrowUpIcon />
          )
        }
        onClick={handleScrollToTop}
      />
    </Box>
  );
};
export default ScrollToTop;
