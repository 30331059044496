import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import iconBackground from '@assets/images/pirulo/faqs-item-icon-bg.svg';

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers([`heading`, `icon`]);

export const faqItems = helpers.defineMultiStyleConfig({
  // The base styles for each part
  baseStyle: {
    heading: {
      fontSize: `md`,
      fontFamily: `heading`,
      lineHeight: `shorter`,
      textTransform: `uppercase`,
      borderColor: `colours.colour-11`
    },
    icon: {
      backgroundImage: iconBackground,
      backgroundSize: `contain`,
      backgroundPosition: `center`,
      backgroundRepeat: `no-repeat`
    }
  }
});
