import React, { useContext } from 'react';
import {
  Flex,
  Box,
  AspectRatio,
  Image,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  useDisclosure,
  Show,
  Hide,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Button
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { LocaleContext } from '@helpers/LocaleProvider';
import LocaleList from '@components/molecules/LocaleList';

const LocaleOptions = () => {
  const { locale } = useContext(LocaleContext);
  const flags = require.context(`../../assets/images/flags`, true, /\.svg$/);
  const { isOpen, onToggle, onClose } = useDisclosure();
  return (
    <Flex width="100%">
      <Show below="md">
        <Accordion allowMultiple variant="localeOptions" width="100%">
          <AccordionItem border="none" width="100%">
            <AccordionButton justifyContent="center" py={3}>
              <AspectRatio
                boxSize="1.5rem"
                ratio="1"
                display="inline-block"
                borderRadius="full"
                border="1px solid white"
                verticalAlign="middle"
                mr={2}
                mt="-0.0625rem"
                overflow="hidden">
                <Image src={flags(`./${locale}.svg`).default} alt={locale} />
              </AspectRatio>
              <Box as="span" alignSelf="flex-end">
                ({locale})
              </Box>
              <ChevronDownIcon boxSize={5} ml={1} />
            </AccordionButton>
            <AccordionPanel p={6}>
              <LocaleList />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Show>
      <Hide below="md">
        <Popover isOpen={isOpen} onClose={onClose} variant="primary">
          <Button
            display="flex"
            alignItems="center"
            m="0 0 0 auto"
            variant="localeDropdown"
            onClick={onToggle}>
            <PopoverTrigger>
              <AspectRatio
                boxSize="1.5rem"
                ratio="1"
                display="inline-block"
                borderRadius="full"
                border="1px solid white"
                verticalAlign="middle"
                mr={2}
                mt="-0.0625rem"
                overflow="hidden">
                <Image src={flags(`./${locale}.svg`).default} alt={locale} />
              </AspectRatio>
            </PopoverTrigger>
            <Box as="span">({locale})</Box>
            <ChevronDownIcon boxSize={5} ml={1} />
          </Button>
          <PopoverContent
            width="100%"
            minWidth={{ md: `12.125rem` }}
            borderRadius={{ md: 8 }}
            pt={6}
            px={6}
            mt={2}>
            <PopoverArrow />
            <PopoverBody
              pt={0}
              px={0}
              pb={6}
              maxHeight="9.6875rem"
              overflow="auto">
              <LocaleList />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Hide>
    </Flex>
  );
};

export default LocaleOptions;
